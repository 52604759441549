<template>
  <div class="wrapper">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 text-center">
        <h2 class="m-4">
          {{ failedTitle }}
        </h2>
        <i class="fa fa-exclamation-circle text-warning result-icon"></i>
        <p class="text-secondary mt-4">
          {{ somethingwentWrong }} <br />
          <span v-if="!isVRW">
            {{ tryAgainLater }}
          </span>
        </p>
        <button v-if="fromLogin" type="button" class="btn btn-primary d-block w-100 text-upper nextbtn" @click.prevent="restart">
          {{$tc("message.restart")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import getTranslation from "@/mixin/translations.js"

export default {
  data () {
    return {
      isVRW: false,
      fromLogin: false
    }
  },
  mounted () {
    if (this.$route.query.from === "login") {
      this.fromLogin = true
    }
    if (this.$route.path === "/nomination/videos") {
      this.isVRW = true
    }
  },
  mixins: [getTranslation],
  computed: {
    failedTitle() {
      return this.getTranslations("sorry") || this.$tc("message.resultHeader", 2)
    },
    somethingwentWrong() {
      return (
        this.getTranslations("somethingWentWrong") ||
        this.$tc("message.resultBody", 1)
      )
    },
    tryAgainLater() {
      return (
        this.getTranslations("tryAgain") ||
        this.$tc("message.resultBody", 2)
      )
    }
  },
  methods: {
    resetNominationData() {
      this.$emit("resetNominationData", null)
    },
    restart () {
      window.history.back()
    }
  }
}
</script>
<style scoped>
svg {
  width: 150px;
  height: 150px;
}
.wrapper {
  max-width: 600px;
  margin: auto;
}
.text-upper {
  text-transform: uppercase;
}
</style>